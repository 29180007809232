.mainContainer{
    max-width: 1080px;
    margin:0 auto;

    @media (min-width:992px) and (max-width:1199px){
        padding-left:25px;
        padding-right:25px;
    }

    @media (min-width:1200px) and (max-width:1500px){
        padding-left:50px;
        padding-right:50px;
    }

    @media (max-width:500px){
        padding-left:15px;
        padding-right:15px;
    }
}

.bold{
    @include headFontBold;
}

a{
    color:#000;
    &:hover{
        text-decoration: none;
        color: #606060;
    }
}

/* BACKGROUND */



/* COLORS */
body{
    color: #333232;
}

/*  others */


p{
    font-size: 15px;
    color: #333232;
    font-weight: 500;
    @media (max-width:500px){ 
        font-size: 13px;               
        margin-top: 1rem;
    }
    @media(min-width:1200px){
        font-size: 16px;     
    }
}

.novisible{
    display: none!important;
}
.showvisible{
    display: block!important;
    padding: 0 !important;
}
.icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	fill: #fff;
}

.centerOfScreen{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); /* Yep! */
        width: 48%;
        height: 59%;
}
