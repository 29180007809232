@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.mainContainer {
  max-width: 1080px;
  margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mainContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .mainContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 500px) {
  .mainContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.bold {
  font-size: 14px;
}

@media (min-width: 576px) {
  .bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .bold {
    font-size: 52px;
  }
}

a {
  color: #000;
}

a:hover {
  text-decoration: none;
  color: #606060;
}

/* BACKGROUND */
/* COLORS */
body {
  color: #333232;
}

/*  others */
p {
  font-size: 15px;
  color: #333232;
  font-weight: 500;
}

@media (max-width: 500px) {
  p {
    font-size: 13px;
    margin-top: 1rem;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 16px;
  }
}

.novisible {
  display: none !important;
}

.showvisible {
  display: block !important;
  padding: 0 !important;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  fill: #fff;
}

.centerOfScreen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Yep! */
  width: 48%;
  height: 59%;
}

body {
  font-family: 'Lato', sans-serif;
}

.btn {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: auto;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
  padding: 8px 15px;
  background-color: #3a3a3a;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  white-space: normal;
  font-size: 13px;
  padding: 10px 18px;
  transition: unset !important;
}

.btn:hover {
  color: #fff;
  background-color: #606060;
}

.header {
  margin: .5rem auto;
  align-items: baseline;
  display: flex;
}

.header__logo {
  max-height: 60px;
  width: auto;
}

.header__sklep {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #3a3a3a;
}

.header__sklep:hover {
  text-decoration: none;
  color: #606060;
}

.header__phone img {
  height: 30px;
  width: auto;
}

@media (max-width: 566px) {
  .header__phone img {
    height: 25px;
    width: auto;
  }
}

@media (max-width: 566px) {
  .header__phone a {
    font-size: 13px;
  }
}

.products {
  margin-top: 5rem;
}

.products__content {
  margin-top: 3rem;
}

.products__btn {
  background-color: transparent;
  color: #3a3a3a;
  border-color: #3a3a3a;
  position: absolute;
  bottom: 0;
}

.products__btn::hover {
  background-color: transparent;
  color: #606060;
  border-color: #606060;
}

.products__btn.przetestuj {
  margin-left: 7rem;
}

.products__img {
  height: 270px;
  margin: auto;
}

@media (max-width: 768px) {
  .products__img {
    text-align: center;
  }
}

.products h4 {
  margin: 1rem 0 1rem;
  font-size: 22px;
  font-weight: 600;
}

.products p {
  margin: 1rem 0 5rem;
  font-size: 14px;
}

.baner {
  background-image: url("../../img/header-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  max-height: 650px;
  position: relative;
  display: table;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.baner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #685858;
  opacity: 0.4;
  z-index: 1;
}

.baner__inner {
  position: relative;
  /* display: table-cell; */
  vertical-align: middle;
  padding: 9rem 0;
  z-index: 2;
}

@media (max-width: 500px) {
  .baner__inner {
    padding: 7rem 0;
  }
}

.baner__content {
  max-width: 1000px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

.baner__content h1 {
  font-size: 4.33333em;
  text-transform: capitalize;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}

@media (max-width: 500px) {
  .baner__content h1 {
    font-size: 3rem;
  }
}

.baner__content p {
  font-size: 1.533rem;
  font-style: italic;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 500px) {
  .baner__content p {
    font-size: 1rem;
  }
}

.baner__btn {
  margin-top: 2rem;
}

.SetYourselfFree .mainContainer {
  background-position: left top;
  background-image: url("../../img/setYourselfFree.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 650px;
  position: relative;
  display: table;
  width: 100%;
}

.PersonalizeYourRide .mainContainer {
  background-position: left top;
  background-image: url("../../img/personalizeYourRide.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 650px;
  position: relative;
  display: table;
  width: 100%;
}

.film {
  margin-top: 5rem;
}

.film .mainContainer {
  background-repeat: no-repeat;
}

.film .mainContainer::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #685858;
  opacity: 0.4;
  z-index: 1;
}

.film__film {
  display: none;
}

.film__inner {
  position: relative;
  /* display: table-cell; */
  vertical-align: middle;
  height: 650px;
  z-index: 2;
}

@media (max-width: 500px) {
  .film__inner {
    height: 350px;
  }
}

.film__content {
  max-width: 1000px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}

.film__content h2 {
  font-size: 4.33333em;
  text-transform: capitalize;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}

@media (max-width: 500px) {
  .film__content h2 {
    font-size: 3rem;
  }
}

.film__content p {
  font-size: 1.533rem;
  font-style: italic;
  font-weight: 700;
  color: #fff;
}

.film .video-control__play {
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: relative;
  margin: 0 auto;
  padding: 5px;
}

.film .video-control__play .icon {
  opacity: 1;
}

.video-is-playing .video--image_with_play, .video-is-paused .video--image_with_play {
  display: block;
  visibility: visible;
  opacity: 1;
}

.video--image_with_play {
  /* display: none;
	opacity: 0;
	visibility: none; */
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in;
}

/* .video {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
} */
#cookie-msg {
  padding: 12px !important;
  background-color: blue !important;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: yellow !important;
}

#cookie-msg .btn-aceptar {
  background-color: yellow !important;
  margin-left: 13px;
  font-size: 13px !important;
  color: blue !important;
  margin: 2px;
  display: inline-block;
}

.USP {
  margin-top: 5rem;
}

.USP__content {
  align-items: center;
}

.USP__content h3 {
  font-size: 1.73333em;
  text-transform: capitalize;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: .6rem;
}

@media (max-width: 768px) {
  .USP__content h3 {
    font-size: 1.3333em;
    margin-top: 2.5rem;
  }
  .USP__content h3.jedzbezlitosnie {
    margin-top: 0rem;
  }
}

.USP__content p {
  padding-right: 2rem;
}

@media (max-width: 768px) {
  .USP__content p {
    margin-bottom: 2rem;
  }
}

.USP__btn {
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .USP .Demojazda p {
    margin-bottom: 0rem;
  }
}

.footer {
  margin-top: 5rem;
  font-size: 12px;
  margin-bottom: 1rem;
}

@media (max-width: 771px) {
  .footer__oddzial {
    margin: 2rem 0 -1rem 0;
  }
}

@media (max-width: 771px) {
  .footer__desktop {
    display: none;
  }
}

@media (min-width: 772px) {
  .footer__desktop {
    display: block;
  }
}

@media (min-width: 772px) {
  .footer__mobile {
    display: none;
  }
}

.footer a {
  font-size: 12px;
}

.footer a:hover {
  text-decoration: none;
}

.footer__bg {
  background: #e0dfdf;
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.footer__bg h3 {
  font-weight: 500;
  font-size: 16px;
}

.footer__bg p {
  font-size: 14px;
}

@media (min-width: 772px) {
  .footer__bg__dane {
    display: flex;
  }
}

.map {
  height: 0;
  width: 100%;
}

.map .mapBox {
  margin-top: 20px;
}
