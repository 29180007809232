.footer{
    margin-top: 5rem;
    font-size: 12px;
    
    &__oddzial{        
        @media(max-width:771px){
            margin: 2rem 0 -1rem 0;
        }
    }
    &__desktop{  
        @media(max-width:771px){
            display: none;
        }
        @media(min-width:772px){
            display: block;
        }
    }
    &__mobile{  
        @media(min-width:772px){
            display: none
        }
    }
    
    a {
        font-size: 12px;
        &:hover{
            text-decoration: none;
        }
    }

    margin-bottom: 1rem;
    &__bg{
        h3 {
            font-weight: 500;
            font-size: 16px;
        }
        p{
            font-size: 14px;;
        }
        background: #e0dfdf;
        margin-bottom: 2rem;
        padding: 1rem 0;
        &__dane{
            @media(min-width:772px){
                display: flex;
            }
        }
    }
}
.map{
    height: 0;
    width: 100%;
    .mapBox {
        margin-top: 20px;
    }
}