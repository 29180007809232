.SetYourselfFree{    
    .mainContainer{
        background-position: left top;
        background-image: url("../../img/setYourselfFree.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        max-height: 650px;
        position: relative;
        display: table;
        width: 100%;
    }
}
.PersonalizeYourRide{    
    .mainContainer{
        background-position: left top;
        background-image: url("../../img/personalizeYourRide.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        max-height: 650px;
        position: relative;
        display: table;
        width: 100%;
    }
}


.film{
    margin-top: 5rem;
    .mainContainer{
        background-repeat: no-repeat;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #685858;
            opacity: 0.4;
            z-index: 1;
        }
    }
    &__film{
        display: none;
    }
    &__inner{
        position: relative;
        /* display: table-cell; */
        vertical-align: middle;
        height: 650px;
        z-index: 2;
        @media(max-width:500px){
            height: 350px;
        }
    }
    &__content{
        max-width: 1000px;
        color: #fff;
        text-align: center;
        margin: 0 auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            width: 90%;
        h2{
            font-size: 4.33333em;
            text-transform: capitalize;
            text-shadow: 0 0 4px rgba(0,0,0,0.4);
            font-style: normal;
            font-weight: 700;
            line-height: 1.2;
            color: #fff;
            @media(max-width:500px){
                font-size:3rem;
            }
        }
        p{
            font-size: 1.533rem;
            font-style: italic;
            font-weight: 700;
            color: #fff;
        }
    }
    .video-control__play {
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: visible;
        opacity: 1;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        position: relative;
        margin: 0 auto;
        padding: 5px;
        .icon {
            opacity: 1;
        }
    }
}


.video-is-playing .video--image_with_play, .video-is-paused .video--image_with_play {
	display: block;
	visibility: visible;
	opacity: 1;
}
.video--image_with_play {
	/* display: none;
	opacity: 0;
	visibility: none; */
	width: 100%;
	height: 100%;
	transition: all 0.2s ease-in;
}
/* .video {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
} */