.products{
    margin-top: 5rem;
    &__content{
        margin-top: 3rem;
    }
    &__btn {
        background-color: transparent;
        color: #3a3a3a;
        border-color: #3a3a3a;
        &::hover{
            background-color: transparent;
            color: #606060;
            border-color: #606060;
        }
        position: absolute;
        bottom: 0;
        &.przetestuj{
            margin-left: 7rem
        }
    }
    &__img{
        height: 270px;
        margin: auto;
        @media (max-width:768px){        
            text-align: center;
        }
    }
    h4{
        margin: 1rem 0 1rem;
        font-size: 22px;
        font-weight: 600;
    }
    p{
        margin: 1rem 0 5rem;
        font-size: 14px;
    }
}