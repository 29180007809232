.baner{
    background-image: url('../../img/header-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    max-height: 650px;
    position: relative;
    display: table;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #685858;
        opacity: 0.4;
        z-index: 1;
    }
    &__inner{
        position: relative;
        /* display: table-cell; */
        vertical-align: middle;
        padding: 9rem 0;
        z-index: 2;
        @media(max-width:500px){
            padding: 7rem 0;
        }
    }
    &__content{
        max-width: 1000px;
        color: #fff;
        text-align: center;
        margin: 0 auto;

        h1{
            font-size: 4.33333em;
            text-transform: capitalize;
            text-shadow: 0 0 4px rgba(0,0,0,0.4);
            font-style: normal;
            font-weight: 700;
            line-height: 1.2;
            color: #fff;
            @media(max-width:500px){
                font-size:3rem;
            }
        }
        p{
            font-size: 1.533rem;
            font-style: italic;
            font-weight: 700;
            color: #fff;
            @media(max-width:500px){
                font-size:1rem;
            }
        }
    }
    &__btn {
        margin-top: 2rem;
    }
}