.btn{
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: inline-block;
	width: auto;
	text-decoration: none;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 2px;
	padding: 8px 15px;
	background-color: #3a3a3a;
	color: #fff;
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	white-space: normal;
    font-size: 13px;
    padding: 10px 18px;
    &:hover {
        color: #fff;
        background-color: #606060;
	}
		transition: unset!important;
}