.header{
    margin: .5rem auto;
    align-items: baseline;
    display: flex;
    &__logo{
        max-height: 60px;
        width: auto;;
    }
    &__sklep {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #3a3a3a;
        &:hover{
            text-decoration: none;
            color: #606060;
        }
    }
    &__phone{
        img{
            height: 30px;
            width: auto;
            @media(max-width:566px){
                height: 25px;
                width: auto;
            }
        }
        a {            
            @media(max-width:566px){
                font-size: 13px;     
            }
        }
    }
}