.USP{
    margin-top: 5rem;
    &__content{
        align-items: center;
        h3 {
            font-size: 1.73333em;
            text-transform: capitalize;
            letter-spacing: 0;
            font-weight: 600;
            margin-bottom: .6rem;
            @media (max-width:768px){ 

                &.jedzbezlitosnie {    
                    margin-top: 0rem;
                }
                
                font-size: 1.3333em;               
                margin-top: 2.5rem;
            }
        }
        p{
            padding-right: 2rem;
            @media (max-width:768px){        
                margin-bottom: 2rem;
            }
        }
    }
    &__btn {
        margin-top: 2rem
    }
    .Demojazda {
        p{
            @media (max-width:768px){        
                margin-bottom: 0rem;
            }
        }

    }
}